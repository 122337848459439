import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Hero from "../../components/SingleService/Common/Hero";
import Recognizing from "../../components/SingleService/DedicatedTeam/Recognizing"
import Dedicated from "../../components/SingleService/DedicatedTeam/Dedicated"
import Choose from "../../components/SingleService/DedicatedTeam/Choose";
import Experience from "../../components/SingleService/Common/Experience";
import Cases from "../../components/CommonComponents/Cases"
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise"
import Technologies from "../../components/SingleService/Common/Technologies"
import Keys from "../../components/SingleService/Common/Keys"
import Reviews from "../../components/MainPage/RCHolder/Reviews"
import dataForDedicatedTeam from "../../information/Services/dataForDedicatedTeam"
import dataForWeb from "../../information/Services/dataForWeb"
import { mainUrl } from "../../js/config"
import dataForProjects from "../../information/CloudSolutions/dataForProjects.json"
import "../../components/SingleService/DedicatedTeam/TeamRoadmap.scss"

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
      },
    },
  ],
}

export default function Startups() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : "";
  const { ogImage, hero, recognizing, dedicated, choose, keys, } = dataForDedicatedTeam
  const { technologies } = dataForWeb
  return (
    <section className="startup">
      <SEO
        title="Dedicated Software Development Company | OS-System"
        description="Want to hire dedicated software development team? OS-System is #1 in terms of expertise diversity. We developed healthcare, e-commerce, logistics, education, and financial projects. The scope of work includes mobile and web apps, APIs, enterprise solutions, etc."
        canonical={`${mainUrl}/services/dedicatedteam/`}
        ogImage={ogImage}
      />
      <Layout showFormPoint={100}>
        <Hero data={hero} portfolioBtn/>
        <Recognizing data={recognizing}/>
        <Experience />
        <Dedicated data={dedicated} />
        <Choose data={choose} />
        <Keys data={keys} />
        <Technologies data={technologies} />
        <Cases
          projects={dataForProjects}
          settings={settings}
          headerText="Work Projects"
          customClass="cloud"
        />
        <div className="dedicated-height-margin" />
        <Reviews isTestimonials={true} />
        <ContactUsExpertise form={0} url={sendUrl} />
      </Layout>
    </section>
  )
}
